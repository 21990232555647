<template>
  <div class="request--book-nego">
    <div class="form--group" v-if="listing && !listing.view_to_offer">
      <div class="left--col">
        <span>{{ $t('general.useNego') }}</span>
      </div>
      <div class="right--col">
        <div class="custom-control custom-checkbox mt-4">
          <input
            type="checkbox"
            class="custom-control-input"
            id="use-nego"
            name="use-nego"
            v-model="useNego"
          />
          <label class="custom-control-label" for="use-nego"></label>
        </div>
      </div>
    </div>
    <div v-show="useNego || (listing && listing.view_to_offer)">
      <v-card class="nego--instruction">
        {{
          listing && listing.view_to_offer
            ? $t('booking.negoViewToOfferInstruction')
            : $t('booking.negoInstruction')
        }}
      </v-card>
      <div class="form--group" v-if="listing && !listing.view_to_offer">
        <div class="left--col">
          <span>{{ `${$t('general.initialPricePer')} ${$t('date.month.title')}` }}</span>
        </div>
        <div class="right--col">
          <div class="input--group">
            <div class="append--left">
              S$
            </div>
            <cleave
              class="basic--input price--input"
              type="text"
              name="new_price"
              :value="pricePerDuration"
              :options="cleaveOption"
              ref="price"
              readonly
            />
          </div>
        </div>
      </div>
      <hr v-if="listing && !listing.view_to_offer" />
      <div class="form--group" :class="{ 'has-error': validation.hasError('newPrice') }">
        <div class="left--col">
          <span>{{
            listing && listing.view_to_offer
              ? `${$t('general.offerPricePer')} ${$t('date.month.title')}`
              : `${$t('general.newPricePer')} ${$t('date.month.title')}`
          }}</span>
        </div>
        <div class="right--col">
          <div class="input--group">
            <div class="append--left">
              S$
            </div>
            <cleave
              class="basic--input price--input"
              type="text"
              name="new_price"
              v-model="newPrice"
              :options="cleaveOption"
              ref="price"
            />
            <span class="val-error mt-2" v-if="validation.hasError('newPrice')">{{
              validation.firstError('newPrice')
            }}</span>
          </div>
        </div>
      </div>
      <div class="form--group">
        <div class="left--col">
          <span>{{ `${$t('general.pricePsf')}` }}</span>
        </div>
        <div class="right--col">
          <div class="input--group">
            <div class="append--left">
              S$
            </div>
            <cleave
              class="basic--input price--input"
              type="text"
              name="new_price"
              :value="newPricePsf"
              :options="cleaveOptionPsf"
              readonly
            />
            <div class="append--right">
              / sqft
            </div>
          </div>
        </div>
      </div>
      <div class="form--group">
        <div class="left--col">
          <span>{{ `${$t('general.newTotalPrice')}` }}</span>
        </div>
        <div class="right--col">
          <div class="input--group">
            <div class="append--left">
              S$
            </div>
            <cleave
              class="basic--input price--input"
              type="text"
              name="new_price"
              :value="newTotalPrice"
              :options="cleaveOption"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import Cleave from 'vue-cleave-component';
export default {
  name: 'user-data-section',
  components: { Cleave },
  data() {
    return {
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      cleaveOptionPsf: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      useNego: false,
    };
  },
  computed: {
    ...mapState({
      listing: state => state.v2.rentTransaction.initiate.listing,
      totalRent: state => state.v2.rentTransaction.initiate.pricing.totalRent,
      duration: state => state.v2.rentTransaction.initiate.duration,
    }),
    newPrice: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.newPrice;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_NEW_PRICE', val);
      },
    },
    pricePerDuration() {
      if (this.totalRent && this.duration) {
        return parseFloat(this.totalRent) / parseFloat(this.duration);
      } else {
        return 0;
      }
    },
    newTotalPrice() {
      if (this.newPrice) {
        return parseFloat(this.newPrice) * parseFloat(this.duration);
      } else {
        return 0;
      }
    },
    newPricePsf() {
      if (this.newPrice && this.listing && this.listing.size_in_square_foot) {
        let result = parseFloat(this.newPrice) / parseFloat(this.listing.size_in_square_foot);
        return parseInt(Math.round((result * 100) / 100));
      } else {
        return 0;
      }
    },
  },
  watch: {
    useNego(val) {
      this.onUseNegoChange(val);
    },
  },
  validators: {
    newPrice: {
      validator(value) {
        return Validator.value(value).required(
          this.listing && this.listing.view_to_offer
            ? this.$i18n.t('errors.offerPrice.required')
            : this.$i18n.t('errors.newPrice.required'),
        );
      },
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onUseNegoChange(val) {
      if (this.totalRent && this.duration) {
        this.newPrice = parseFloat(this.totalRent) / parseFloat(this.duration);
      }
    },
  },
};
</script>

<style scoped></style>
